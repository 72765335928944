import React from 'react';
import {Box, Button, Flex, Image, Link, Spacer} from '@chakra-ui/react';

import { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useMediaQuery from "./hooks/useMediaQuery";

const Link2 = ({ page, selectedPage, setSelectedPage }) => {
    const lowerCasePage = page.toLowerCase();
    return (
      <AnchorLink
        className={`${
          selectedPage === lowerCasePage ? "text-yellow" : ""
        } hover:text-yellow transition duration-500`}
        href={`#${lowerCasePage}`}
        onClick={() => setSelectedPage(lowerCasePage)}
      >
        {page}
      </AnchorLink>
    );
  };



const NavBarV1 = ({ accounts, setAccounts, isTopOfPage, selectedPage, setSelectedPage}) => {
    const [isMenuToggled, setIsMenuToggled] = useState(false);
    const isDesktop = useMediaQuery("(min-width: 768px)");
    const navbarBackground = isTopOfPage ? "" : "bg-red";
   
    const isConnected = Boolean(accounts[0]);

    async function connectAccount()
    {
        if (window.ethereum){
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccounts(accounts);
        }
    }
    return (
        <nav className={`${navbarBackground} w-full fixed `}>
        <Flex justify = "space-between" align = "center" padding = "10px 10px 10px">
        
            { /* left side Social media Icons*/ }   

            {/*Right Side - Sections and connect*/ }
            <Flex
                justify = "space-around"
                align = "center"
                //width = "40%"
                //padding = "30px"
            >

            <h4 className=" mr-7 rounded-full bg-red p-3 before:content-nerveDripLogoWhite"></h4>
          
            {/* DESKTOP NAV */}
            {isDesktop ? (

                    <div className="  flex justify-between gap-16 font-pixelify text-sm font-semibold">
                        <Link2
                        page="Mint"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                        />
                        <Link2
                        page="Rupture"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                        />
                        <Link2
                        page="RoadMap"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                        />
                        <Link2
                        page="Contact"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                        />
                    </div>
                    ) : (
                    
                    <button
                        //className="rounded-full bg-red p-5 snap-center"
                        className = " mr-7 rounded-full bg-red p-4 before:content-menuBar"
                        onClick={() => setIsMenuToggled(!isMenuToggled)}
                    >
                                            
                    </button>
                    
                
            )}
            <Spacer />
                {isConnected ? (
                <Box margin = "0 45px 0px 25px" fontFamily="Pixelify Sans">Connected</Box>
                ) : (
                    
                   

                    <Button 
                    backgroundColor="#D6517D"
                    borderRadius="5px"
                    boxShadow="0px 4px 4px 2px #0F0F0F"
                    color="white"
                    cursor="pointer"
                    fontFamily="Pixelify Sans"
                    padding="15px"
                    margin="0 20px"
                    onClick = {connectAccount}>Connect Wallet</Button>
                    
                )}
          

            </Flex>

            {/* Connect */}
                <div className="flex justify-between mx-auto">
                    
                        
                    {/* MOBILE MENU POPUP */}
                    {!isDesktop && isMenuToggled && (
                    <div className="fixed left-0 bottom-0 h-full bg-blue w-[300px]">
                        {/* CLOSE ICON */}
                        <div className="flex justify-end p-14">
                        <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                            <img alt="close-icon" src="../assets/close-icon.svg" />
                        </button>
                        </div>

                        {/* MENU ITEMS */}
                        <div className="flex flex-col gap-10 ml-[33%] text-2xl text-deep-blue">
                        <Link2
                            page="Mint"
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                        />
                        <Link2
                            page="Rupture"
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                        />
                        <Link2
                            page="RoadMap"
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                        />

                        <Link2
                            page="Contact"
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                        />
                        </div>
                    </div>
                    )}

                </div>


          
        </Flex>
        </nav>
        

        
    );
};

export default NavBarV1;