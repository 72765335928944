//import Navbar from "./scenes/Navbar";
import Landing from "./scenes/Landing";
import DotGroup from "./scenes/DotGroup";
import MySkills from "./scenes/MySkills";
import LineGradient from "./components/LineGradient";
import Projects from "./scenes/Projects";
import Contact from "./scenes/Contact";
import Footer from "./scenes/Footer";
import useMediaQuery from "./hooks/useMediaQuery";
import Testimonials from "./scenes/Testimonials";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

import MainMint from './MainMint';
import NavBarV1 from './NavBarV1';
import LandingV2 from './LandingV2';
import Rupture from './Rupture';
import RoadMap from './RoadMap';

import {Box, Button, Flex, Image, Link, Spacer, Center} from '@chakra-ui/react';

function App() {
  const [selectedPage, setSelectedPage] = useState("mint");
  const [isTopOfPage, setIsTopOfPage] = useState(true);
  const isDesktop = useMediaQuery("(min-width: 1060px)");

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsTopOfPage(true);
        setSelectedPage("mint");
      }
      if (window.scrollY !== 0) setIsTopOfPage(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    
    <div >

      <div className="w-5/6 s md:h-full">
      <NavBarV1 accounts={accounts} 
                setAccounts={setAccounts}
                isTopOfPage={isTopOfPage}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
      />

        {isDesktop && (
          <DotGroup
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
        )}
        <motion.div
          margin="0 0 0 0"
          amount="all"
          onViewportEnter={() => setSelectedPage("mint")}
        >


          <LandingV2 setSelectedPage={setSelectedPage} accounts={accounts} 
                      setAccounts={setAccounts} />
         
        </motion.div>
      </div>
      <LineGradient />
      <div className="w-5/6 mx-auto md:h-full ">
        <motion.div
          margin="0 0 -200px 0"
          amount="all"
          onViewportEnter={() => setSelectedPage("rupture")}
        >
          <Rupture />
        </motion.div>
      </div>
      <LineGradient />
      <div className="w-5/6 mx-auto">
        <motion.div
          margin="0 0 -200px 0"
          amount="all"
          onViewportEnter={() => setSelectedPage("roadmap")}
        >
          <RoadMap />
        </motion.div>
      </div>

      <LineGradient />
      <div className="w-5/6 mx-auto md:h-full">
        <motion.div
          margin="0 0 -200px 0"
          amount="all"
          onViewportEnter={() => setSelectedPage("contact")}
        >
          <Contact />
        </motion.div>
      </div>
      <Footer />
    </div>



  );
}

export default App;
