import Facebook from "../assets/social-media-icons/facebook_32x32.png";
import Twitter from "../assets/social-media-icons/twitter_32x32.png";
import Email from "../assets/social-media-icons/email_32x32.png";
import {Box, Button, Flex, Image, Link, Spacer, HStack} from '@chakra-ui/react';

const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center md:justify-start my-10 gap-7">
      
      <Flex justify = "space-around" width = "1600%" padding = "20 75px">
        <HStack spacing='24px'>
          <a
            className="hover:opacity-50 transition duration-500"
            href="https://mobile.twitter.com/nervedrip"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="twitter-link" src="../assets/twitter.png" />
          </a>
          <a
            className="hover:opacity-50 transition duration-500"
            href="https://www.instagram.com/nervedripgames/?igshid=NGExMmI2YTkyZg%3D%3D"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="instagram-link" src="../assets/instagram.png" />
          </a>
          <a
            className="hover:opacity-50 transition duration-500"
            href="https://discord.gg/JvXWJDSTra"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="disc-link" src="../assets/discord.png" />
          </a>
        </HStack>  
      </Flex>

      

      
    </div>

  );
};

export default SocialMediaIcons;
