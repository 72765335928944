import LineGradient from "./components/LineGradient";
import useMediaQuery from "./hooks/useMediaQuery";
import { motion } from "framer-motion";

const RoadMap = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="roadmap" className="pt-10 pb-24">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-pixelify font-semibold text-4xl mb-5">
            ROAD<span className="text-red">MAP</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-10 mb-7">

          </p>
        </motion.div>


      </div>

      {/* RUPTURE */}
      <div className="md:flex md:justify-between mt-16 gap-32">
        {/* EXPERIENCE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-pixelify font-semibold text-5xl">OPEN BETA</p>
              <p className="font-pixelify font-semibold text-3xl mt-3">
                JAN 2024
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
                Open beta available for download on Google Play Store.
          </p>
        </motion.div>

        {/* INNOVATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-pixelify font-semibold text-5xl">MINT DAY</p>
              <p className="font-pixelify font-semibold text-3xl mt-3">
                APRIL 2024
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            Rollout of Genesis NFTs
          </p>
        </motion.div>
        {/* IMAGINATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-pixelify font-pixelify text-5xl">FULL LAUNCH</p>
              <p className="font-pixelify font-pixelify text-3xl mt-3">
                JULY 2024
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            Genesis holders join Clans. NFT staking opens, Full Game goes Live
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default RoadMap;
