import SocialMediaIcons from "./components/SocialMediaIcons";
import useMediaQuery from "./hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";

import React, {useState} from 'react';
import { ethers, BigNumber} from 'ethers';
import {Box, Button, Flex, Input, Text, Center, Spacer, Image, ChakraProvider, Heading } from '@chakra-ui/react';
import ruptureNFT from './RuptureNFT.json';

import customTheme from "./theme";
import ColorModeSwitch from "./ColorModeSwitch";


const ruptureNFTAddress = "0x277B0284365fDD570416c484c5D7bA31B2503b71";


const LandingV2 = ({ setSelectedPage, accounts, setAccounts }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");

  const [mintAmount, setMintAmount] = useState(1);
  const isConnected = Boolean(accounts[0]);

  async function handleMint() {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
        ruptureNFTAddress,
        ruptureNFT.abi,
        signer
        );
        try {
            const response = await contract.mint(BigNumber.from(mintAmount), {
                value: ethers.utils.parseEther((0.2*mintAmount).toString()),
            });
            console.log('response: ', response);

        } catch (err) {
            console.log("error: ", err)
        }
    }
}

  const basicBoxStyles = {
    display: 'stretch',
    alignItems: '',
    justifyContent: '',
    textAlign: '',
    boxSize: 'l',
    color: 'white',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: '20px',
    px: 0, 
    background:
      'url("https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExZ25mbmVjMnZ0am14d3I4Z3FnMXdscG5qcnNyMm5hZWF1ZnRuNTJydyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/XB7ORe1OJJJX8lCgeB/giphy.gif") center/cover ',
    width: '130%',
  
   
  
  
    }


const handleDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount-1);
};

const handleIncrement = () => {
    if (mintAmount >= 3) return;
    setMintAmount(mintAmount+1);
};

  return (
    <Box sx ={basicBoxStyles} filter = 'hueRotate(140deg)' >
    <section
      id="mint"
      className="   "
      
    >
      

   

      

        {/* HEADINGS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >

        <Flex
          justify = "center"  align = "center"
          paddingTop={150}    
        >
          
                <div  >
                    <Heading fontSize="xx-large" textShadow="0 10px #000000"  paddingTop = "30px" paddingRight = "10px" fontFamily={"Pixelify Sans"} > 
                    Rupture
                    </Heading>
                    <Text fontSize="x-large" textShadow="0 5px #000000" 
                        letterSpacing={"-5.5%"} fontFamily={"Pixelify Sans"} paddingBottom={0} 
                    > 
                        Every day The Breach is growing.                      
                    </Text>

                <Flex paddingTop={290}>
                <div>
                <AnchorLink
                  className="bg-gradient-rainblue text-deep-blue rounded-sm py-1 px-1 font-semibold
                    hover:bg-blue hover:text-white transition duration-500 w-full h-full 
                    flex items-center justify-centerw font-pixelify "
                  onClick={() => setSelectedPage("contact")}
                  href="#contact"
                >
                  Wen Mint?
                </AnchorLink>
                </div>
                
                <AnchorLink
                  className="rounded-r-sm bg-gradient-rainblue py-1 pr-1"
                  onClick={() => setSelectedPage("contact")}
                  href="#contact"
                >
                  <div>
                  <div className="bg-deep-blue hover:text-red transition duration-500 w-full h-full 
                      flex items-center justify-center py-0 px-1 font-pixelify">
                    Get Involved.
                  </div>
                  </div>

                </AnchorLink>
                
                </Flex>
                <Flex paddingTop={-20} paddingLeft = {50}>
                <SocialMediaIcons />
                </Flex>
                </div>
                {isConnected ? (
                    <div>
                        <Flex justify = "center"  align = "center">
                            <Button
                                backgroundColor="#D6517D"
                                borderRadius="5px"
                                boxShadow="0px 2px 2px 1px #0F0F0F"
                                color="white"
                                cursor="pointer"
                                fontFamily="inherit"
                                padding="15px"
                                marginTop="10px"
                                onClick = {handleDecrement}
                            >
                            -
                            </Button>
                            <Input
                                readOnly
                                fontFamily="inherit"
                                width="100px"
                                height="40px"
                                textAlign="center"
                                paddingLeft="19px"
                                marginTop="10px"
                                type="number"
                                value = {mintAmount} 
                            />
                            <Button
                                backgroundColor="#D6517D"
                                borderRadius="5px"
                                boxShadow="0px 2px 2px 1px #0F0F0F"
                                color="white"
                                cursor="pointer"
                                fontFamily="inherit"
                                padding="15px"
                                marginTop="10px" 
                                onClick = {handleIncrement}
                            >
                            +
                            </Button>
                        </Flex>
                        <Button
                            backgroundColor="#D6517D"
                            borderRadius="5px"
                            boxShadow="0px 2px 2px 1px #0F0F0F"
                            color="white"
                            cursor="pointer"
                            fontFamily="inherit"
                            padding="15px"
                            marginTop="10px" 
                            onClick = {handleMint}
                        >
                        Minting Not Available yet..
                       
                        </Button>
                    </div>
                ) : (
                  <div>
                      
                    <Text
                    fontSize="xx-large" textShadow="0 3px #000000" 
                    letterSpacing={"-5.5%"} fontFamily={"Pixelify Sans"}
                    color="#D6517D" marginRight="-10px" paddingTop = "250px"  paddingBottom = "100px"                                   
                    > 
                    Mint day coming soon...
                    </Text>
                    </div>
                )}
       
           
           

         

        </Flex>
        <div className= "">
          
        </div>

      </motion.div>
      
 
    
  </section>
  </Box>
  );
};

export default LandingV2;
