import LineGradient from "./components/LineGradient";
import useMediaQuery from "./hooks/useMediaQuery";
import { motion } from "framer-motion";
import {Box, Button, Flex, Image, Link, Spacer, AspectRatio} from '@chakra-ui/react';

const Rupture = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="rupture" className="pt-10 pb-24">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-pixelify font-pixelify text-4xl mb-5 p-10" >
            GAMEPLAY<span className="text-red">TRAILER</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-10 mb-7">

          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1] "
            >
              <AspectRatio width = "720px" >
                <iframe
                  title='Rupture Gameplay Trailer'
                  src="https://www.youtube.com/embed/rVtwvEgN5P8?si=SNLOd9X1KaWamnJ3" 
                  allowFullScreen
                />
              </AspectRatio>
            </div>
          ) : (
            // This video will have equal sides
              <AspectRatio width = "100%">
                <iframe
                  title='Rupture Gameplay Trailer'
                  src="https://www.youtube.com/embed/rVtwvEgN5P8?si=SNLOd9X1KaWamnJ3" 
                  allowFullScreen
                />
              </AspectRatio>
          )}
        </div>
      </div>

      {/* SKILLS */}
      <div className="md:flex md:justify-between mt-16 gap-32">


      </div>
    </section>
  );
};

export default Rupture;
